/* Global Styles */
body {
   
    margin: 0;
    padding: 0;
    box-sizing: border-box;
        font-family: "New Amsterdam", sans-serif;
        font-weight: 200npm;
        font-style: normal;
      
      
    
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  

  
  /* Home Container Styles */
  .home-container {
    text-align: center;
  }
  
  /* Header Styles */
  header {
   
    background-size: cover;
    background-position: center;
    border: 1px solid #00308F; /* Reduced border size */
    padding: 2rem; /* Added padding inside the border */
    margin: 2rem; /* Added margin outside the header */
    box-sizing: border-box; /* Ensures padding doesn't affect width */
    border-radius: 5px;
  }
  
  header h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  header p {
    font-size: 1.25rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* About Section */
  .about {
    background-color: #f8f9fa;
  }
  
  .about h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .about p {
    font-size: 1rem;
    line-height: 1.6;
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
  }
  
  /* Services Section */
  .services h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .services .row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .services .col-md-4 {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    padding: 2rem;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .services .col-md-4:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .services h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .services p {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  /* Footer Styles */
 
  
  /* Responsive Design */
  @media (max-width: 768px) {
    header h1 {
      font-size: 2rem;
    }
  
    header p {
      font-size: 1rem;
    }
  
    .services .row {
      flex-direction: column;
    }
  
    .services .col-md-4 {
      margin: 0 auto 1.5rem;
    }
  }
  