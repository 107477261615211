/* src/components/Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #00308F;
    padding: 10px 20px;
    color: white;
  }
  
  .navbar-left .site-name {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 2px;
  }
  
  .navbar-right {
    display: flex;
    gap: 20px;
  }
  
  .nav-button {
    background: none;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .nav-button:hover {
    color: #ddd;
  }
  
  .nav-button svg {
    font-size: 24px;
  }
  